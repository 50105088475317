@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);

body {
  font-family: 'Roboto';
  margin: 0;
}

.app-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0 auto;
  min-height: calc(100vh - 2*64px);
}

.footer {
  padding-left: 10px;
  padding-right: 10px;
  background-color: #f5f5f5;
  display: flex;
  justify-content: space-between;
  .small {
    font-size: small;
    color: grey;
  }
}
