.top-bar {
  display: grid;
  grid-template-columns: 1fr min-content 1fr;
  grid-template-areas: ". main-nav right-nav";

  .main-nav {
    grid-area: main-nav;
    display: flex;
    justify-content: center;
    a:not(:last-child) {
      margin-right: 10px;
    }
  }

  .right-nav {
    grid-area: right-nav;
    display: flex;
    justify-content: flex-end;
  }
}
