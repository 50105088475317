.badge {
  margin: 20px 0;
  display: flex;
  align-items: center;
  max-width: 500px;
  img {
    height: 80px;
    flex: 0 0 80px;
    border-radius: 50%;
    margin-right: 25px;
  }
}
